
import { Options, Vue, setup } from "vue-class-component";
import { Form, Field, CellGroup, Button, Toast } from "vant";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ERROR_CODE } from "@/const/errorCode";
import { AllState } from "../../store";

interface RegisterFormParams {
  username: string;
  password: string;
}

@Options({
  components: {
    Form,
    Field,
    CellGroup,
    Button
  }
})
export default class Register extends Vue {
  register = setup(() => {
    const store = useStore<AllState>();
    const username = ref<string>("");
    const password = ref<string>("");
    const router = useRouter();

    let loading = ref<boolean>(false);

    const register = (opt: RegisterFormParams) => {
      // 注册新用户
      loading.value = true;
      store.state.IM.connect
        .registerUser(opt)
        .then(() => {
          Toast("注册成功");
          router.push("/login");
        })
        .catch((e) => {
          console.log(e, "eeee");
          let errInfo: any = {};
          if (e.data) {
            errInfo = JSON.parse(e.data);
          }
          if (errInfo.error === ERROR_CODE.registerUnique) {
            Toast("用户已存在");
          } else {
            Toast("注册失败");
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const onSubmit = (params: RegisterFormParams): void => {
      register(params);
    };

    const toLogin = (): void => {
      router.push("/login");
    };

    return {
      username,
      password,
      loading,
      toLogin,
      onSubmit
    };
  });
}
